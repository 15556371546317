import { makeAutoObservable } from 'mobx';
import { RpcError } from 'grpc-web';
import { AppStore } from '../app';
import {
  BffPopUpManagerGetAllRoute,
  BffPopUpManagerUpdateRoute
} from '@boints/grpc';
import { WebSocketService } from '../../services/transport';
import { PopUpAcknowledgeType, PopUpName, PopupTriggerType } from '../../pages/pop-up-manager';

export class PopUpManagerStore {
  public error: RpcError | null = null;

  public popUps: BffPopUpManagerGetAllRoute.ResponseType['popUps'] = [];

  constructor(private rootStore: AppStore) {
    makeAutoObservable(this);
  }

  *getAll() {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      const { popUps }: BffPopUpManagerGetAllRoute.ResponseType = yield WebSocketService
        .sendRequest<BffPopUpManagerGetAllRoute.ResponseType, BffPopUpManagerGetAllRoute.RequestType>({
          method: 'bff_getPopUps',
          data: {},
        });

      this.popUps = popUps.map((popUp) => ({ ...popUp, triggers: popUp.triggers.map((trigger) => ({ ...trigger, id: Math.random() })) }));
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }

  *update(params: BffPopUpManagerUpdateRoute.RequestType) {
    this.rootStore.isLoading = true;
    this.error = null;

    try {
      yield WebSocketService
        .sendRequest<BffPopUpManagerUpdateRoute.ResponseType, BffPopUpManagerUpdateRoute.RequestType>({
          method: 'bff_updatePopUp',
          data: {
            ...params,
          },
        });

        this.popUps.forEach((popUp) => {
          if (popUp.id === params.popUp!.id) {
            popUp.acknowledgeType = params.popUp!.acknowledgeType;
            popUp.defaultValidTime = params.popUp!.defaultValidTime;
            popUp.name = params.popUp!.name;
            popUp.triggers = params.popUp!.triggers;
          }
        });
    } catch (err) {
      this.error = err as RpcError;

      this.rootStore.notificationStore.enqueueSnackBar({
        message: this.error.message,
        variant: 'error',
      });
    }

    this.rootStore.isLoading = false;
  }
}
