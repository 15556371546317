export const bff = {
  login: {
    microserviceName: 'bff',
    serviceName: 'AuthService',
    method: 'login',
  },
  loginByToken: {
    microserviceName: 'bff',
    serviceName: 'AuthService',
    method: 'loginByToken',
  },

  getPopUps: {
    microserviceName: 'bff',
    serviceName: 'PopUpManagerService',
    method: 'getAll',
  },
  updatePopUp: {
    microserviceName: 'bff',
    serviceName: 'PopUpManagerService',
    method: 'update',
  }
};