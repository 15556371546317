import React from 'react';
import { makeAutoObservable } from 'mobx';
import {
  AuthStore,
  UserStore,
  BlacklistStore,
  WhitelistStore,
  DailyReportStore,
  DoubleBointsStore,
  CashoutVersionStore,
  TransactionStore,
  TicketStore,
  SettingsStore,
  SeasonsStore,
  TournamentsStore,
  NotificationStore,
  ThemeStore,
  RewardStore,
  MotivatianlCurrencyStore,
  UserQualityStore,
  HrsStatsStore,
  NotificationsTemplatesStore,
  DailyCoinsGoalStore,
  PopUpManagerStore,
} from '../';
import { HrsStore } from '../hrs';
import { BonusStore } from '../reward/bonus.store';
import { MotivatianlCurrencyV2Store } from '../motivatioanl-currency-v2';

export class AppStore {
  isLoading = false;

  authStore = new AuthStore(this);
  userStore = new UserStore(this);
  doubleBointsStore = new DoubleBointsStore(this);
  dailyReportStore = new DailyReportStore(this);
  blacklistStore = new BlacklistStore(this);
  whitelistStore = new WhitelistStore(this);
  cashoutVersionStore = new CashoutVersionStore(this);
  transactionStore = new TransactionStore(this);
  ticketStore = new TicketStore(this);
  settingsStore = new SettingsStore(this);
  bonusStore = new BonusStore(this);
  seasonsStore = new SeasonsStore(this);
  tournamentsStore = new TournamentsStore(this);
  notificationStore = new NotificationStore(this);
  rewardStore = new RewardStore(this);
  themeStore = new ThemeStore(this);
  motivatianlCurrencyStore = new MotivatianlCurrencyStore(this);
  motivatianlCurrencyV2Store = new MotivatianlCurrencyV2Store(this);
  hrsStore = new HrsStore(this);
  userQualityStore = new UserQualityStore(this);
  notificationsTemplatesStore = new NotificationsTemplatesStore(this);
  hrsStatsStore = new HrsStatsStore(this);
  dailyCoinsGoalStore = new DailyCoinsGoalStore(this);
  popUpManagerStore = new PopUpManagerStore(this);

  constructor() {
    makeAutoObservable(this);
  }
}

const AppStoreContext = React.createContext(new AppStore());

export const useStores = () => React.useContext(AppStoreContext);
